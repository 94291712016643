import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import { Icon, Select, translate as translateConstructor, Button as HaldorButton } from '@haldor/ui';

import { getHaldorAssignments, getMicrosoftEducationAssignments, clearAssignmentsFromStore } from 'actions/assignments'

import Tooltip from '@mui/material/Tooltip';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, svSE, enUS, nbNO, daDK, useGridApiRef, useGridApiContext, gridClasses } from '@mui/x-data-grid';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import './_MyAssignmentsV2.scss';
import SelectMultiple from '../Overview/TableFilterComponents/SelectMultiple';
import { Button, FormControlLabel, FormGroup, Popover, Stack, Checkbox, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { AssignmentTaskStatusCanDevelop, AssignmentTaskStatusNotComplete, AssignmentTaskStatusNotStarted, AssignmentTaskStatusStarted, AssignmentTaskStatusSubmitted } from 'UI/Icons';
import LoadingCard from 'components/Cards/LoadingCard';
import NoItemsFoundLayout from './NoItemsFoundLayout';
import CustomColumnSelector from 'components/DataGrid/CustomColumnSelector';
import DatePickerFromTo from 'containers/Forms/Partials/DatePickerFromTo';
import { getRootUrl } from 'helpers/url';

/**
 * this is a map of the locale to the data grid language
 */
const localeMapDataGrid = {
	'EN-US': enUS,
	'SV-SE': svSE,
	'NB-NO': nbNO,
	'DA-DK': daDK,
};

const CustomToolbar = React.memo(({ filterValues, onChangeFilter, resetTableFilter, groupId, mySections, assignmentStatus, subjects, isLoading, assignmentTypes }) => {
	const translations = useSelector(state => state.Languages.translations);
	let translate = translateConstructor(translations);

	const groupOptions = useMemo(() => mySections?.filter(item => item?.id > 0)?.map(item => ({ id: item.id, title: item.title })) || [], [mySections]);
	const assessmentStatusOptions = [{ id: 'NOT_PUBLISHED_ASSESSMENTS', title: translate('Missing published assessments') }];
	const assignmentStatusOptions = useMemo(() => assignmentStatus != null ? assignmentStatus.map(item => ({ id: item.id, title: item.value })) : [], [assignmentStatus]);

	const subjectsOptions = useMemo(() => {
		if (subjects == null) return [];

		const uniqueSubjectObjects = subjects.reduce((uniqueSubjects, subject) => {
			if (!uniqueSubjects.some(item => item.title === subject.subjectTitle)) {
				uniqueSubjects.push({ id: subject.subjectId, title: subject.subjectTitle });
			}
			return uniqueSubjects;
		}, []);

		if (uniqueSubjectObjects.length > 1)
			uniqueSubjectObjects.sort((a, b) => a.title.localeCompare(b.title));

		return uniqueSubjectObjects;
	}, [subjects]);

	const initialSelectedGroups = useMemo(() => filterValues?.groups ?? [], [filterValues?.groups]);
	const initialSelectedAssignmentTypes = useMemo(() => filterValues?.assignmentTypes ?? [], [filterValues?.assignmentTypes]);
	const initialSelectedAssessmentStatus = useMemo(() => filterValues?.assessmentStatus ?? [], [filterValues?.assessmentStatus]);
	const initialSelectedAssignmentStatus = useMemo(() => filterValues?.assignmentStatus ?? [], [filterValues?.assignmentStatus]);
	const initialSelectedSubjects = useMemo(() => filterValues?.subjects ?? [], [filterValues?.subjects]);

	const noFilterApplied = useMemo(() => {
		return !filterValues || Object.values(filterValues).every(arr => arr.length === 0);
	}, [filterValues]);

	if (isLoading) return null;

	return (
		<div>
			<GridToolbarContainer sx={{
				paddingLeft: '1.5rem',
				paddingTop: '1rem',
				marginBottom: '1rem',
			}}>
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('subjects', selectedOptions)} initialSelectedItems={initialSelectedSubjects} options={subjectsOptions} label={translate('Subjects')} sx={{ minWidth: 300, maxWidth: 350 }} />
				{groupId == null ? <SelectMultiple onChange={(selectedOptions) => onChangeFilter('groups', selectedOptions)} initialSelectedItems={initialSelectedGroups} options={groupOptions} label={translate('Groups')} sx={{ minWidth: 300, maxWidth: 350 }} /> : null}
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('assignmentTypes', selectedOptions)} initialSelectedItems={initialSelectedAssignmentTypes} options={assignmentTypes} label={translate('Assignment types')} sx={{ minWidth: 300, maxWidth: 350 }} />
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('assessmentStatus', selectedOptions)} initialSelectedItems={initialSelectedAssessmentStatus} options={assessmentStatusOptions} label={translate('Assessment status')} sx={{ minWidth: 350, maxWidth: 350 }} />
				<SelectMultiple onChange={(selectedOptions) => onChangeFilter('assignmentStatus', selectedOptions)} initialSelectedItems={initialSelectedAssignmentStatus} options={assignmentStatusOptions} label={translate('Access status')} sx={{ minWidth: 300, maxWidth: 350 }} />
				{noFilterApplied ? null : <Button onClick={resetTableFilter} sx={{ padding: '7.5px 14px;', color: 'rgba(0, 0, 0, 0.6)' }} variant="outlined" startIcon={<DeleteIcon />}>{translate('Clear filter')}</Button>}
				<CustomColumnSelector id="MyAssignmentAsStaff" />
			</GridToolbarContainer >
		</div>
	);
});

function MyAssignmentsTableAsStaff({ pageSize, section }) {
	const assignments = useSelector(state => state.assignments.assignments);
	const teamsAssignments = useSelector(state => state.assignments.teamsAssignments);
	const translations = useSelector(state => state.Languages.translations);
	const mySections = useSelector(state => state.sections.educationGroups);
	const assignmentTypes = useSelector(state => state.status.status.assignmentTypes);
	const assignmentTaskStatuses = useSelector(state => state.status.status.assignmentTaskStatus);
	const assignmentStatus = useSelector(state => state.status.status.assignmentStatus);
	const services = useSelector(state => state.Services.availableServices);
	const haldorAssignmentsFetchedUTC = useSelector(state => state.assignments.haldorAssignmentsFetchedUTC);
	const teamsAssignmentsFetchedUTC = useSelector(state => state.assignments.teamsAssignmentsFetchedUTC);
	const currrentUser = useSelector(state => state.user.currentUser);
	var datagridLanguage = null;

	if (currrentUser != null && currrentUser.lcid != null && localeMapDataGrid[currrentUser.lcid.toUpperCase()] != null) {
		datagridLanguage = localeMapDataGrid[currrentUser.lcid.toUpperCase()];
	} else {
		datagridLanguage = enUS;
	}


	const dispatch = useDispatch();
	const history = useHistory();
	let translate = translateConstructor(translations);

	const assignmentTypesOptions = assignmentTypes != null ? assignmentTypes.map(item => { return { id: item.id, title: item.value } }) : [];

	const [isLoading, setIsLoading] = useState(true);
	const [allSubjects, setAllSubjects] = useState(null);
	const [currentAssignmentList, setCurrentAssignmentList] = useState(null);
	const [startDate, setStartDate] = useState(moment().month() < 7 ? moment().month(0).date(1) : moment().month(6).date(1));
	const [endDate, setEndDate] = useState(moment().month() < 7 ? moment().month(6).date(31) : moment().month(11).date(31));

	const currentTableFilter = useRef(null);
	const apiRef = useGridApiRef();

	useEffect(() => {
		/**
		 * Set the default term to 'semester' when the component is mounted.
		 */
		onTermChange('semester');
		if ((assignments == null && teamsAssignments == null) || (section != null && section.archived)) {
			setIsLoading(true);
			updateData(false);
		}

		if (section == null) {
			currentTableFilter.current = JSON.parse(localStorage.getItem("assignments_filter_objects"));
		}

		return () => {
			if (section != null && section.archived) {
				dispatch(clearAssignmentsFromStore());
			}
		};
	}, []);

	/**
	 * This useEffect will be triggered when the assignments are fetched from the API
	 * or when loading is false and datagrid is rendered.
	 */
	useEffect(() => {
		if (assignments != null && assignments.length > -1) {
			setCurrentAssignmentList(assignments);
		} else if (teamsAssignments != null && teamsAssignments.length > -1) {
			setCurrentAssignmentList(teamsAssignments.filter(item => item.reference != null).map(item => item.reference));
		}
	}, [assignments]);

	/**
	 * Once currentAssignmentList is set, we will fetch the subjects from the assignments and set isLoading to false. When isLoading is false, the the apiRef will be initiated
	 */
	useEffect(() => {
		if (currentAssignmentList != null && currentAssignmentList.length > -1) {
			fetchSubjects();
			setIsLoading(false);
		}
	}, [currentAssignmentList]);

	/**
	 * When isLoading is false, we can start to filter the assignments. This will make sure the table will be updated correctly
	 */
	useEffect(() => {
		if (!isLoading && allSubjects != null) {
			filterAssignments();
		}
	}, [isLoading, allSubjects, section]);


	/**
	 * Once DataGrid is loaded, we will set the sortModel from the localstorage. (This can happen whenever the component is mounted)
	 */
	useEffect(() => {
		if (apiRef != null && apiRef.current != null && apiRef.current.setSortModel != null) {
			let sortModel = JSON.parse(localStorage.getItem("assignments_sort_objects"));
			if (sortModel != null)
				apiRef.current.setSortModel(sortModel);
		}
	}, [apiRef, isLoading]);

	const onTermChange = (value) => {
		if (value != '') {
			let dStartDate = moment();
			let dDueDate = moment();

			switch (value) {
				case 'year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(7).date(1);
						dDueDate = moment().add(1, 'year').month(6).date(31);
					}

					break;
				case 'previous-year':
					if (moment().month() < 7) {
						dStartDate = moment().subtract(2, 'year').month(7).date(1);
						dDueDate = moment().subtract(1, 'year').month(6).date(31);
					}
					else {
						dStartDate = moment().subtract(1, 'year').month(7).date(1);
						dDueDate = moment().subtract(0, 'year').month(6).date(31);
					}
					break;
				case 'semester':
					if (moment().month() < 7) {
						dStartDate = moment().month(0).date(1);
						dDueDate = moment().month(6).date(31);
					} else {
						dStartDate = moment().month(6).date(1);
						dDueDate = moment().month(11).date(31);
					}

					break;
			}


			setStartDate(dStartDate);
			setEndDate(dDueDate);
		}
	}

	/**
	 * Filters the assignments based on the provided criteria.
	 */
	const filterAssignments = () => {
		let filteredAssignments = currentAssignmentList;
		/**
		 * This function uses the section prop from the component to filter the assignments. 
		 * Mainly used when the component is used in a group context.
		 */
		if (section != null) {
			filteredAssignments = filteredAssignments.filter((assignment) => {
				return assignment.sectionId == section.id;
			});
		}

		/**
		 * This filter will be applied if filters has been selected in the table filter component.
		 */
		if (currentTableFilter.current) {
			const { groups, assignmentTypes, assessmentStatus, assignmentStatus, subjects } = currentTableFilter.current;

			if (groups && groups.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return groups.some((group) => group.id == assignment.sectionId);
				});
			}

			if (assignmentTypes && assignmentTypes.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignmentTypes.some((type) => type.id == assignment.assignmentType);
				});
			}

			if (assessmentStatus && assessmentStatus.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignment.publishedAssessments < assignment.totalTasks;
				});
			}

			if (assignmentStatus && assignmentStatus.length > 0) {
				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignmentStatus.some((status) => status.id == assignment.status || (status.id == 'ASSIGNMENT_DRAFT' && assignment.published == "0001-01-01T00:00:00"));
				});
			}

			if (subjects && subjects.length > 0) {
				let filteredSubjectIds = allSubjects
					.filter((subject) => subjects.some((selectedSubject) => selectedSubject.title === subject.subjectTitle))
					.map((subject) => subject.subjectId);

				filteredAssignments = filteredAssignments.filter((assignment) => {
					return assignment.courses.some((course) =>
						filteredSubjectIds.includes(course.subjectId)
					);
				});
			}
		}

		if (apiRef && apiRef.current && apiRef.current.setRows) {
			apiRef.current.setRows(filteredAssignments);
			apiRef.current.setPageSize(pageSize ?? 50);
		}
	};

	/**
	 * This function is called when the user selects a filter in the table filter component.
	 * @param {*} filterType 
	 * @param {*} selectedOptions 
	 */
	const onChangeFilter = (filterType, selectedOptions) => {
		const filterObjects = {
			...currentTableFilter.current,
			[filterType]: selectedOptions
		};

		localStorage.setItem("assignments_filter_objects", JSON.stringify(filterObjects));
		currentTableFilter.current = filterObjects;
		filterAssignments();
	};

	const onSortModelChange = (details) => {
		localStorage.setItem("assignments_sort_objects", JSON.stringify(details));
		if (apiRef != null && apiRef.current != null)
			apiRef.current.setSortModel(details);
	}

	/**
	 * This function is called when the user clicks the 'Reset Filter' button in the table filter component.
	 */
	const resetTableFilter = () => {
		localStorage.removeItem("assignments_filter_objects");
		currentTableFilter.current = null;
		filterAssignments();
	}

	/**
	 * fetches all subjects from the assignments and sets them in the state 'subjects' as an array of objects with the properties 'subjectId' and 'subjectTitle'.
	 */
	const fetchSubjects = async () => {
		let subjects = [];

		currentAssignmentList.forEach(assignment => {
			if (assignment.courses == null || assignment.courses.length == 0) return null;

			assignment.courses.forEach(course => {
				const subjectId = course.subjectId;
				const subjectTitle = course.subjectTitle;
				const existingSubject = subjects.find(subject => subject.subjectId === subjectId);
				if (!existingSubject) {
					subjects.push({
						subjectId,
						subjectTitle
					});
				}
			});
		});

		setAllSubjects(subjects);
	}

	/**
	 * This function is called when the user clicks on a row in the data grid.
	 * @param {*} params 
	 */
	const handleRowClick = (params) => {
		let assignmentId = params?.row?.externalID ?? params?.row?.id;
		var url = `${getRootUrl()}assignment/${assignmentId}`;
		history.push(url);
	};

	const renderAssignmentTitle = (assignment) => {
		return (
			<Stack direction="row" spacing={1}>
				<div style={{ width: '20px' }}>
					{assignment != null && assignment.externalID != null ?
						<div className='teams-meeting-indicator'>
							<Icon name='Teams' />
						</div> : null}
				</div>
				<div>{assignment.title}</div>
			</Stack>
		)
	}

	const renderDataGrid = () => {
		const columns = [
			{
				field: 'title',
				headerName: translate('Title'),
				headerClassName: 'table-header',
				renderHeader: (params) => <div style={{ marginLeft: '25px' }}>{translate('Title')}</div>,
				renderCell: (params) => renderAssignmentTitle(params?.row),
				valueGetter: (params) => { return params?.row?.title; },
				minWidth: 250,
				flex: 1
			},
			{
				field: 'sectionName',
				headerName: translate('Group'),
				headerClassName: 'table-header',
				minWidth: 150,
				flex: 1,
				valueGetter: (params) => `${params?.row?.section?.title || ''}`,
			},
			{
				field: 'dueDate',
				headerName: translate('Due Date'),
				headerClassName: 'table-header',
				description: 'This column has a value getter and is not sortable.',
				width: 120,
				renderCell: (params) => renderDueDate(params?.row?.dueDate),
			},
			{
				field: 'statistics',
				headerClassName: 'table-header',
				headerName: translate('Statistic'),
				renderHeader: (params) => renderStatisticsHeader(),
				renderCell: (params) => renderStatisticsCells(params),
				width: 160,
				sortable: false,
				disableColumnMenu: true
			},
			{
				field: 'assignmentType',
				headerClassName: 'table-header',
				headerName: translate('Assignment type'),
				minWidth: 150,
				flex: 1,
				renderCell: (params) => {
					const assignmentType = assignmentTypesOptions.find(option => option.id === params?.row?.assignmentType);
					return assignmentType ? assignmentType.title : '';
				},
			},
			{
				field: 'information',
				headerClassName: 'table-header',
				headerName: translate('Information'),
				minWidth: 150,
				flex: 1,
				sortable: false,
				renderCell: (params) => renderInformationColumn(params),
				valueGetter: (params) => `${params?.row?.submittedTasks || 0} / ${params?.row?.totalTasks || 0}`,
			}
		];

		return (<div>
			<div style={{ backgroundColor: '#FFFFFF' }}>
				<DataGrid
					sx={{
						width: '100%',
						'--DataGrid-overlayHeight': '300px',
						[`& .${gridClasses.cell}`]: {
							py: 2.5,
						},
					}}
					autoHeight
					localeText={datagridLanguage != null ? {
						...datagridLanguage.components.MuiDataGrid.defaultProps.localeText,
					} : null}
					columns={columns}
					rows={currentAssignmentList == null ? [] : currentAssignmentList}
					density='standard'
					loading={isLoading}
					getRowClassName={(params) => { return 'table-row' }}
					onRowClick={handleRowClick}
					onSortModelChange={onSortModelChange}
					disableColumnFilter={true}
					getRowHeight={() => 'auto'}
					disableColumnSelector={true}
					apiRef={apiRef}
					slots={{
						noRowsOverlay: () => (<NoItemsFoundLayout title={'No assignments found'} />),
						toolbar: () => (
							<CustomToolbar
								filterValues={currentTableFilter.current}
								onChangeFilter={onChangeFilter}
								resetTableFilter={resetTableFilter}
								groupId={section != null ? section.id : null}
								mySections={mySections}
								assignmentStatus={assignmentStatus}
								subjects={allSubjects}
								assignmentTypes={assignmentTypesOptions}
							/>
						)
					}}
				/>
			</div>
		</div>);
	};

	const renderCircleWithData = (color, number) => {
		return (
			<div style={{ fontSize: '12px', borderRadius: '50%', width: '25px', height: '25px', backgroundColor: color, backgroundSize: '50% 100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{number}
			</div>
		);
	}

	const renderStatisticsHeader = (params) => {
		return (
			<Stack direction="row" spacing={0.5}>
				<div style={{ display: 'flex', height: '25px', width: '25px' }}>
					<AssignmentTaskStatusNotStarted />
				</div>
				<div style={{ display: 'flex', height: '25px', width: '25px' }}>
					<AssignmentTaskStatusStarted />
				</div>
				<div style={{ display: 'flex', height: '25px', width: '25px' }}>
					<AssignmentTaskStatusNotComplete />
				</div>
				<div style={{ display: 'flex', height: '25px', width: '25px' }}>
					<AssignmentTaskStatusCanDevelop />
				</div>
				<div style={{ display: 'flex', height: '25px', width: '25px' }}>
					<AssignmentTaskStatusSubmitted />
				</div>
			</Stack>
		);
	}

	const renderStatisticsCells = (params) => {
		let assignment = params?.row;
		if (assignment == null || assignment.externalID != null) {
			return <div>{translate('Not available')}</div>
		};

		let labelNotStarted = useMemo(() => {
			const status = assignmentTaskStatuses.find(item => item.id === 'ASSIGNMENT_TASK_NOT_STARTED');
			return status ? status.value : '';
		}, [assignmentTaskStatuses]);

		let labelStarted = useMemo(() => {
			const status = assignmentTaskStatuses.find(item => item.id === 'ASSIGNMENT_TASK_STARTED');
			return status ? status.value : '';
		}, [assignmentTaskStatuses]);

		let labelSubmitted = useMemo(() => {
			const status = assignmentTaskStatuses.find(item => item.id === 'ASSIGNMENT_TASK_SUBMITTED');
			return status ? status.value : '';
		}, [assignmentTaskStatuses]);

		let labelNotComplete = useMemo(() => {
			const status = assignmentTaskStatuses.find(item => item.id === 'ASSIGNMENT_TASK_NOT_COMPLETE');
			return status ? status.value : '';
		}, [assignmentTaskStatuses]);

		let labelCanDevelop = useMemo(() => {
			const status = assignmentTaskStatuses.find(item => item.id === 'ASSIGNMENT_TASK_CAN_DEVELOP');
			return status ? status.value : '';
		}, [assignmentTaskStatuses]);


		let notStarted = assignment.stats == null || assignment.stats.length == 0 ? 0 : assignment.stats.find(item => item.assignmentTaskStatusId === "ASSIGNMENT_TASK_NOT_STARTED")?.count;
		let started = assignment.stats == null || assignment.stats.length == 0 ? 0 : assignment.stats.find(item => item.assignmentTaskStatusId === "ASSIGNMENT_TASK_STARTED")?.count;
		let submitted = assignment.stats == null || assignment.stats.length == 0 ? 0 : assignment.stats.find(item => item.assignmentTaskStatusId === "ASSIGNMENT_TASK_SUBMITTED")?.count;
		let notComplete = assignment.stats == null || assignment.stats.length == 0 ? 0 : assignment.stats.find(item => item.assignmentTaskStatusId === "ASSIGNMENT_TASK_NOT_COMPLETE")?.count;
		let canDevelop = assignment.stats == null || assignment.stats.length == 0 ? 0 : assignment.stats.find(item => item.assignmentTaskStatusId === "ASSIGNMENT_TASK_CAN_DEVELOP")?.count;
		return (
			<Stack direction="row" spacing={0.5}>
				<div style={{ display: 'flex', height: '25px' }}>
					<Tooltip title={labelNotStarted}>
						{renderCircleWithData('#f3f3f3', notStarted)}
					</Tooltip>
				</div>
				<div style={{ display: 'flex', height: '25px' }}>
					<Tooltip title={labelStarted}>
						{renderCircleBorder('#e2c30b', started)}
					</Tooltip>
				</div>
				<div style={{ display: 'flex', height: '25px' }}>
					<Tooltip title={labelNotComplete}>
						{renderCircleWithData('#fefdde', notComplete)}
					</Tooltip>
				</div>
				<div style={{ display: 'flex', height: '25px' }}>
					<Tooltip title={labelCanDevelop}>
						{renderCircleWithData('#d7e4ff', canDevelop)}
					</Tooltip>
				</div>
				<div style={{ display: 'flex', height: '25px' }}>
					<Tooltip title={labelSubmitted}>
						{renderCircleBorder('#4879d4', submitted)}
					</Tooltip>
				</div>
			</Stack>
		);
	}


	const renderCircleBorder = (color, data = '', backgroundColor = 'white') => {
		return (
			<div style={{ fontSize: '12px', borderRadius: '50%', width: '25px', height: '25px', backgroundColor: backgroundColor, border: `2px solid ${color}`, backgroundSize: '50% 100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				{data}
			</div>
		);
	}

	const renderDueDate = (date) => {
		if (date == null) return null;

		//convert date which is utc to local time
		var localDate = moment.utc(date).local();
		return (
			<div>
				{localDate.format('YYYY-MM-DD')}
			</div>
		);
	}

	const onDateChange = (dates) => {
		setStartDate(dates.start);
		setEndDate(dates.end);
	};

	const updateData = (forceReload = true) => {
		if (services.haldor) {
			// fetch from haldor if section is archived
			if (section != null && section.archived == true) {
				setIsLoading(true);
				dispatch(getHaldorAssignments(section.graphId, moment(startDate).utc().format('YYYY-MM-DD hh:mm'), moment(endDate).utc().format('YYYY-MM-DD hh:mm')));
				return;
			}

			// fetch assignments if they are not already fetched or if haldorAssignmentsFetchedUTC is older than 10 minutes
			if ((assignments == null || forceReload || moment().diff(moment(haldorAssignmentsFetchedUTC), 'minutes') > 10)) {
				setIsLoading(true);
				dispatch(getHaldorAssignments(null, moment(startDate).utc().format('YYYY-MM-DD hh:mm'), moment(endDate).utc().format('YYYY-MM-DD hh:mm')));
			}
		} else if (!services.haldor && services.microsoft) {
			if ((teamsAssignments == null || forceReload || moment().diff(moment(teamsAssignmentsFetchedUTC), 'minutes') > 10)) {
				setIsLoading(true);
				dispatch(getMicrosoftEducationAssignments());
			}
		}
	}

	const renderInformationColumn = (params) => {
		let assignment = params?.row;

		if (assignment == null)
			return null;

		let status = assignmentStatus.find(item => item.id === assignment.status);

		/**
		 * We need to do this since the draft status will never be set as status on assignmentTask
		 */
		if (assignment.published == null || assignment.published == "0001-01-01T00:00:00") {
			status = assignmentStatus.find(item => item.id === 'ASSIGNMENT_DRAFT');
		}

		return (
			<Stack spacing={1} direction="row" useFlexGap flexWrap="wrap">
				{assignment.publishedAssessments != null && assignment.publishedAssessments > 0 ? <div className='card-meta'>
					<div style={{ display: 'inline-block' }}>{translate('assessed-tasks')}{' '}{assignment.publishedAssessments}</div>
				</div> : null}
				<div className='card-meta'>
					{status.value}
				</div>
				{assignment.groupEnabled ? <div className='card-meta'>{translate('group-assignment')}</div> : null}
			</Stack>
		);
	}

	if (isLoading) {
		return (<LoadingCard count={3}></LoadingCard>)
	}


	return (
		<div>
			<Grid className='mb-3' container justifyContent="flex-start" alignItems="center" spacing={2}>
				<Grid item xs={12} sm={12} md={9}>
					<div className="form">
						<div className="form-row">
							<DatePickerFromTo
								values={{
									start: moment(startDate),
									end: moment(endDate),
								}}
								onChange={onDateChange}
							/>
						</div>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={3}>
					<div className='term-change' style={{ paddingBottom: '1.2rem' }}>
						<Select
							onChange={onTermChange}
							options={[
								{
									label: translate('current-schoolyear'),
									value: 'year',
								},
								{
									label: translate('previous-schoolyear'),
									value: 'previous-year',
								},
								{
									label: translate('current-semester'),
									value: 'semester',
								},
							]}
						>
							<strong style={{ marginBottom: '0.2rem' }}>{translate('select-period')}</strong>
						</Select>
					</div>
					<HaldorButton type='secondary' onClick={updateData}>{translate('Update')}</HaldorButton>
				</Grid>
			</Grid>

			{renderDataGrid()}
		</div>
	);
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAssignmentsTableAsStaff);