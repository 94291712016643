import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { appInsights } from 'lib/appInsights';

import msal, { getUser, loginRequest, clearInteractionProgress } from 'lib/msal';

import { Button, Block, translate } from '@haldor/ui';

class InteractionRequired extends Component {

	componentDidMount = () => {
		const user = getUser();
		appInsights.trackTrace({ message: "EDU012 - Token error (invalid_grant or interaction_required)" }, {
			error: this.props.error,
		});
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	runLogin = () => {
		let request = loginRequest;
		if (this.props.error != null) {
			if (this.props.error.claims != null) {
				request.claims = this.props.error.claims;
			}

			appInsights.trackTrace({ message: 'EDU018 | Check claims on InteractionRequired page' }, {
				error: this.props.error,
			});
		}

		if (microsoftTeams.app.isInitialized()) {
			let url = '/Auth'
			if (this.props.error != null) {
				if (this.props.error.claims != null) {
					url += `?claims=${encodeURI(this.props.error.claims)}`;
				}
			}
			microsoftTeams.authentication.authenticate({
				url: url,
			})
				.then((response) => {
					appInsights.trackTrace({ message: 'EDU018a | MSTeams authenticate successCallback' }, {
						response: response,
					});
					this.props.history.replace('/');
				})
				.catch((error) => {
					appInsights.trackTrace({ message: 'EDU018b | MSTeams authenticate failureCallback' }, {
						error: error,
					});
				});
		} else {
			clearInteractionProgress();
			msal.loginRedirect(request);
		}
	}

	renderInformation = () => {
		if (this.props.error != null) {
			if (this.props.error.errorCode == 'interaction_required') {
				return <div>
					<h3>{this.props.translate('Sorry for bothering you')}</h3>

					<p style={{ marginTop: '1.5rem' }}>
						{this.props.translate('Something went wrong and you need to sign in again.')}
					</p>

					{getUser() != null ?
						<p style={{ marginTop: '1.65rem' }}>
							{this.props.translate('Currently logged in as')} {getUser().name}

							<div className="size-12 color--meta" style={{ marginBottom: '.75rem' }}>
								{getUser().username}
							</div>

							<Button onClick={this.runLogin} style={{ marginRight: 10 }}>
								{this.props.translate('log-in')}
							</Button>

							<Button type="secondary" onClick={() => msal.logout()}>
								{this.props.translate('Log out')}
							</Button>
						</p>
						: null}
				</div>
			}
		}

		return <div>
			<h3>{this.props.translate('you-are-almost-ready')}</h3>

			<p style={{ marginTop: '1.65rem' }}>
				{this.props.translate('consent-first-person-in-tenant-message')}
			</p>

			<a href="https://portal.haldor.se" target="_blank" style={{ display: 'inline-block', marginTop: '1.65rem' }}>
				<Button type="primary">
					Portal
				</Button>
			</a>

			<p style={{ marginTop: '1.65rem' }}>
				{this.props.translate('consent-if-you-are-not-an-administrator-message')}
			</p>

			{getUser() != null ?
				<p style={{ marginTop: '1.65rem' }}>
					{this.props.translate('Currently logged in as')} {getUser().name}

					<div className="size-12 color--meta" style={{ marginBottom: '.75rem' }}>
						{getUser().username}
					</div>

					<Button type="secondary" onClick={() => msal.logout()}>
						{this.props.translate('Log out')}
					</Button>
				</p>
				: null}
		</div>
	}

	render() {
		if (window.location.pathname == '/tab-configure' || window.location.pathname == '/course-plan-configure') {
			return this.renderInformation();
		}

		return <div>
			<div style={{ maxWidth: 650, margin: '0 auto', marginTop: '3rem' }}>
				<Block>
					{this.renderInformation()}
				</Block>
			</div>
		</div>
	}

}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
	}
}

export default withRouter(connect(mapStateToProps)(InteractionRequired));