import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate, getActiveLanguage } from '@haldor/ui';
import sniffer from 'sniffer';
import { getUser, onMsalTokenError } from 'lib/msal';
import { withRouter } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";

import schoolHandling from 'helpers/schoolHandling';
import roleHandling from 'helpers/roleHandling';
import { trackNoGroups } from 'helpers/insights';

import { ROLE_TEACHER, ROLE_STUDENT, ROLE_MENTOR } from 'constants/roles';

import { setUser, getMe } from 'actions/user';
import { getEducationGroups, getSectionByGraphId } from 'actions/sections';
import { getTenant, setActiveTeamsTab } from 'actions/user';
import { getTypes } from 'actions/status';
import { markNotificationAsRead } from 'actions/notifications';
import { getTeamsTab } from '../constants/teamsTabs';

import { getSchoolServices } from 'actions/schools';

import Header from 'teams/components/Header';
import DeviceHeader from 'components/HeaderV2/Device';
import Error from 'components/Presentation/Error/Error';

import InteractionRequired from 'containers/Errors/InteractionRequired';
import RequiredDataError from 'containers/Errors/RequiredDataError';

import { Spinner } from 'UI';
import ZendeskWidget from 'lib/zendeskWidget';

import 'assets/css/main.scss';
import User from '_class/User';

class App extends Component {

	constructor(props) {
		super(props);

		this.state = {
			background: '#f3f2f1',
			loading: true,
			groupForm: false,
			error: null,
			showSession: false,
		};
	}

	isDesktop = () => {
		if (sniffer.isTablet) {
			return true;
		}

		if (sniffer.isDesktop) {
			return true;
		}

		return false;
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	getData = () => {
		const user = getUser();
		const tab = getTeamsTab();

		let timer = setTimeout(() => {
			this.setState({ showSession: true });
		}, 1000 * 5);

		this.props.setActiveTeamsTab(tab);

		microsoftTeams.app.getContext().then((context) => {
			if (context.page.subPageId != null && context.page.subPageId != "") {
				if (this.getParameterByName('notificationId', context.page.subPageId) != null) {
					this.props.markNotificationAsRead(this.getParameterByName('notificationId', context.page.subPageId));
				}

				this.props.history.push(context.page.subPageId);
			}

			this.props.getTenant().then(() => {
				Promise.all([
					this.props.getSectionByGraphId(context.team.groupId),
					this.props.getMe(),
					this.props.setUser(user),
					this.props.getTypes(getActiveLanguage()),
				])
					.then(() => {
						const { currentUser, group } = this.props;

						const user = new User(this.props.currentUser);

						if (group != null) {
							let teacherRole = false;
							schoolHandling.setSchoolOnUser(currentUser, group.schoolID);

							var activeSchool = user.getActiveSchool();
							if (currentUser.schools != null && currentUser.schools.length > 0) {
								currentUser.schools.forEach(school => {
									if (school.id == group.schoolID) {
										let foundTeacherRole = school.userRoles.find(us_role => {
											return us_role.toUpperCase() == ROLE_TEACHER;
										});

										if (foundTeacherRole != null) {
											teacherRole = true;
										}
									}
								});

								let role = teacherRole ? ROLE_TEACHER : ROLE_STUDENT;

								schoolHandling.fetchSchool(group.schoolID, currentUser);
								roleHandling.fetchRole(activeSchool?.id, currentUser, role);
							}

							this.props.getSchoolServices(group.schoolID).then(() => {
								this.props.getEducationGroups().then(() => {
									if (this.props.groups == null || this.props.groups.length == 0) {
										trackNoGroups(currentUser.id);
									}

									this.setState({ loading: false });
									clearTimeout(timer);
								});
							});
						}
					}).catch((error) => {
						this.setState({ loading: false, error: error });
						clearTimeout(timer);
					});
			});
		});
	}

	componentDidMount() {
		this.getData();
	}

	render() {
		if (this.state.loading) {
			return <div style={{ textAlign: 'center' }}>
				<Spinner center />

				{this.state.showSession ?
					<span style={{ color: 'var(--color--meta)' }}>
						Session ID: {localStorage.getItem('hsid')}
					</span>
					: null}
			</div>
		}

		if (this.state.error != null) {
			if (this.state.error?.data.errorCode == 'invalid_grant' || this.state.error?.data.errorCode == 'interaction_required') {
				return <InteractionRequired error={this.state.error?.data} />
			} else {
				return <RequiredDataError error={this.state.error} />
			}
		}

		if (this.props.currentUser != null) {
			const { currentUser } = this.props;

			if (currentUser.schools == null || currentUser.schools.length < 1 || currentUser.roles == null || currentUser.roles.length < 1) {
				return (
					<div style={{ backgroundColor: this.state.background }}>
						<Header />

						<div className="login-content">
							<h1>
								{this.props.translate('something-went-wrong')}
							</h1>

							<p className="login-content__subtitle">
								{this.props.translate('error-no-schools-or-roles')}
							</p>
						</div>
					</div>
				);
			}

			return (
				<div className="tab-content" style={{ backgroundColor: this.state.background }}>
					{!this.isDesktop() ?
						<DeviceHeader />
						: <Header />}

					<Error />
					<ZendeskWidget language={this.props.tenant.lcid} />

					<div className="app-comp-content content">
						{this.props.children}
					</div>
				</div>
			);
		} else {
			return <Spinner center />
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
		currentUser: state.user.currentUser,
		activeSchool: state.user.activeSchool,
		activeRole: state.user.activeRole,
		groups: state.sections.educationGroups,
		group: state.sections.graphSection,
		tenant: state.user.tenant,
	};
}

export default withRouter(connect(mapStateToProps, {
	setUser,
	getEducationGroups,
	getTenant,
	getSchoolServices,
	getMe,
	setActiveTeamsTab,
	getSectionByGraphId,
	getTypes,
	markNotificationAsRead,
})(App));